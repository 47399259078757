import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { VideoPreviewPage } from "./videopreview"


const Page = (props) => {
  const { 
    gbp // Us pricing configuration
   } = useStaticQuery(query)
    .site
      .siteMetadata
        .pricingConfiguration

  return (
    <>
      <VideoPreviewPage checkoutUrls={gbp} />
    </>
  )
}

const query = graphql`
query UkVideoPreviewPage {
  site {
    siteMetadata {
      pricingConfiguration {
        gbp {
          ebook {
            price
            oldPrice
            url
          }
          complete {
            price
            oldPrice
            url
          }
          video {
            price
            oldPrice
            url
          }
        }
      }
    }
  }
}
`


export default Page
